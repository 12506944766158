import React, { useEffect, useState } from 'react'
import { useTranslation }                         from 'react-i18next'
import {
  Box,
  Button,
  Container, FormControl,
  Grid, InputLabel, MenuItem,
  Paper, Select,
}                                 from '@mui/material'
import AlertSimpleDialog          from '../../components/alert-simple-dialog'
import { useApi }                 from '../../utils/api'
import { useNavigate, useParams } from 'react-router-dom'
import { useSnackbar }            from 'notistack'
import ShortFormControl           from '../../components/short-form-control'

export default function CategoryPage() {

  const api = useApi()
  const navigate = useNavigate()
  const { categoryId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [categories, setCategories] = useState([])
  const [values, setValues] = useState({
    title: '',
    note: '',
    parentId: '',
  })
  const [shownDeleteDialog, setShownDeleteDialog] = useState(false)

  useEffect(() => {
    (async () => {
      if (!isNaN(categoryId)) {
        let category = await api.category.detail(categoryId).catch(() => ({}))
        setValues({
          ...category,
          parentId: category.parent?.id,
        })
      }
      let cats = await api.category.list().catch(() => ({}))
      setCategories((prev) => {
        return cats?.data?.filter(c => !c.parent?.id && c.id !== parseInt(categoryId)) ?? []
      })
    })()
  }, [categoryId])

  const handleCloseDeleteDialog = (result) => {
    setShownDeleteDialog(false)
    if (result) {
      api.category.delete(categoryId).then(() => {
        enqueueSnackbar(t('Category deleted'), {
          variant: 'success'
        })
        navigate('/dashboard/categories')
      }).catch(error => {
        enqueueSnackbar(t('Failed to delete category'), {
          variant: 'error'
        })
      })
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    let data = {
      ...values,
    }
    try {
      let category = await api.category.save(data)
      navigate(`/dashboard/category/${category.id}`)
      enqueueSnackbar(t('Category saved'), {
        variant: 'success'
      })
    } catch (e) {
      enqueueSnackbar(t('Failed to save category'), {
        variant: 'error'
      })
    }
  }

  const handleChange = (prop) => (event) => {
    handleChangeDirect(prop, event.target.value)
  }

  const handleChangeDirect = (prop, value) => {
    setValues({ ...values, [prop]: value });
  }

  return (
    <Container component="main">
      <Grid component={'form'} onSubmit={onSubmit} item xs={12}>
        <Paper sx={{ p: 2, width: '100%' }}>
          <Grid container sx={{ width: '100%' }} spacing={2}>
            <Grid item xs={12}>
              <ShortFormControl
                name={'title'}
                title={t('Title')}
                value={values.title}
                handleChange={handleChange} />
            </Grid>
            <Grid item xs={12}>
              <ShortFormControl
                name={'note'}
                title={t('Note')}
                value={values.note}
                handleChange={handleChange} />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="parent-category-select-label">{t('Parent category')}</InputLabel>
                <Select
                  labelId="parent-category-select-label"
                  id="parent-category-select"
                  value={values?.parentId}
                  label={t('Parent category')}
                  onChange={handleChange('parentId')}
                >
                  { categories.map(cat => (<MenuItem key={`provider-${cat.id}`} value={cat.id}>{cat.title}</MenuItem>)) }
                </Select>
              </FormControl>
            </Grid>

          </Grid>
        </Paper>
        <Box sx={{ mt: 2, px: 1 }}>
          <Button type={'submit'} variant={'contained'}>{t('Save category')}</Button>
          { !isNaN(categoryId) && (<Button type={'button'} color={'error'} variant={'contained'} sx={{ ml: 2 }} onClick={() => { setShownDeleteDialog(true) }}>{t('Delete')}</Button>) }
        </Box>
      </Grid>
      <AlertSimpleDialog
        title={t('Deleting category')}
        message={t('delete-confirm-msg', { name: values.name })}
        open={shownDeleteDialog}
        onClose={handleCloseDeleteDialog}
      />
    </Container>
  )

}