import React, { useEffect, useState }                  from 'react'
import { useTranslation }                              from 'react-i18next'
import {
  Box,
  Button,
  Container, FormControl,
  FormControlLabel, FormGroup,
  Grid, InputLabel, MenuItem,
  Paper, Select, Switch, Typography,
}                                                      from '@mui/material'
import AlertSimpleDialog                               from '../../components/alert-simple-dialog'
import { useApi }                                      from '../../utils/api'
import { useNavigate, useParams }                      from 'react-router-dom'
import { useSnackbar }                                 from 'notistack'
import { dateFromInputStringToISO, dateToInputString } from '../../utils/dateutils'
import { DateTime }                                    from 'luxon'
import ShortFormControl                                from '../../components/short-form-control'
import useProviders                                    from '../../hooks/useProviders'
import { ProviderBillingCodes }                        from './ProviderBillingCodes'
import Consts                                          from '../../consts'
import BillingCodeOverview                             from './BillingCodeOverview'

export default function ServicePage() {

  const api = useApi()
  const navigate = useNavigate()
  const { serviceId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [providers] = useProviders()
  const [categories, setCategories] = useState([])
  const [values, setValues] = useState({
    name: '',
    number: '',
    pattern: '',
    type: Consts.Service.type_microPayment,
    enabled: false,
    activeFrom: dateToInputString(DateTime.now().toISO()),
    activeTo: dateToInputString(DateTime.now().plus({ month: 1 }).toISO()),
    url: '',
    projectId: '',
  })
  const [shownDeleteDialog, setShownDeleteDialog] = useState(false)

  useEffect(() => {
    (async () => {
      if (!isNaN(serviceId)) {
        let service = await api.service.detail(serviceId).catch(() => ({}))
        setValues({
          ...service,
          activeFrom: dateToInputString(service.activeFrom),
          activeTo: dateToInputString(service.activeTo),
        })
      }
      let cats = await api.category.list().catch(() => ({}))
      setCategories((prev) => {
        return cats?.data ?? []
      })
    })()
  }, [serviceId])

  const handleCloseDeleteDialog = (result) => {
    setShownDeleteDialog(false)
    if (result) {
      api.service.delete(serviceId).then(() => {
        enqueueSnackbar(t('Service deleted'), {
          variant: 'success'
        })
        navigate('/dashboard/services')
      }).catch(error => {
        enqueueSnackbar(t('Failed to delete service'), {
          variant: 'error'
        })
      })
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    let data = {
      ...values,
      activeFrom: dateFromInputStringToISO(values.activeFrom),
      activeTo: dateFromInputStringToISO(values.activeTo),
      messagesDefinition: {}
    }
    try {
      let service = await api.service.save(data)
      navigate(`/dashboard/services/${service.id}`)
      enqueueSnackbar(t('Service saved'), {
        variant: 'success'
      })
    } catch (e) {
      enqueueSnackbar(t('Failed to save service'), {
        variant: 'error'
      })
    }
  }

  const handleChange = (prop) => (event) => {
    handleChangeDirect(prop, event.target.value)
  }

  const handleChangeDirect = (prop, value) => {
    setValues({ ...values, [prop]: value });
  }

  return (
    <Container component="main">
      <Grid component={'form'} onSubmit={onSubmit} item xs={12}>
        <Paper sx={{ p: 2, width: '100%' }}>
          <Grid container sx={{ width: '100%' }} spacing={2}>
            <Grid item xs={12} md={8}>
              <ShortFormControl
                name={'name'}
                title={t('Name')}
                value={values.name}
                handleChange={handleChange} />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormGroup>
                <ShortFormControl
                  name={'projectId'}
                  title={t('Project ID')}
                  value={values.projectId}
                  handleChange={handleChange}
                  inputProps={{
                    type: 'number',
                  }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={4}>
              <ShortFormControl
                name={'number'}
                title={t('Number')}
                value={values.number}
                handleChange={handleChange} />
            </Grid>
            <Grid item xs={12} md={8}>
              <ShortFormControl
                name={'pattern'}
                title={t('Pattern')}
                value={values.pattern}
                handleChange={handleChange} />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <ShortFormControl
                  name={'activeFrom'}
                  title={t('Active from')}
                  value={values.activeFrom}
                  handleChange={handleChange}
                  inputProps={{
                    type: 'datetime-local',
                  }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <ShortFormControl
                  name={'activeTo'}
                  title={t('Active to')}
                  value={values.activeTo}
                  handleChange={handleChange}
                  inputProps={{
                    type: 'datetime-local',
                  }}
                />

              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel control={<Switch />} checked={values.enabled} onChange={(e) => {
                  handleChangeDirect('enabled', e.target.checked)
                }} label={t('Enabled')} />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="category-select-label">{t('Type')}</InputLabel>
                <Select
                  labelId="type-select-label"
                  id="type-select"
                  value={values?.type ?? ''}
                  label={t('Type')}
                  onChange={handleChange('type')}
                >
                  <MenuItem key={`type-premium`} value={Consts.Service.type_premium}>{t(Consts.Service.type_premium)}</MenuItem>
                  <MenuItem key={`type-micropayments`} value={Consts.Service.type_microPayment}>{t(Consts.Service.type_microPayment)}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {categories?.length > 0 && <>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="category-select-label">{t('Category')}</InputLabel>
                  <Select
                    labelId="category-select-label"
                    id="category-select"
                    value={values?.categoryId ?? ''}
                    label={t('Category')}
                    onChange={handleChange('categoryId')}
                  >
                    { categories.map(cat => (<MenuItem key={`category-${cat.id}`} value={cat.id}>{cat.title}</MenuItem>)) }
                  </Select>
                </FormControl>
              </>}
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <ShortFormControl
                  name={'url'}
                  title={t('Url')}
                  value={values.url}
                  handleChange={handleChange}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Paper>
        <Box sx={{ mt: 2, px: 1 }}>
          <Button type={'submit'} variant={'contained'}>{t('Save service')}</Button>
          { !isNaN(serviceId) && (<Button type={'button'} color={'error'} variant={'contained'} sx={{ ml: 2 }} onClick={() => { setShownDeleteDialog(true) }}>{t('Delete')}</Button>) }
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ mt: 2, px: 1 }}>
          <Typography variant="h4" gutterBottom>{t('Provider billing codes')}</Typography>
        </Box>
        {providers?.length > 0 && <>
          {providers.filter(pr => pr.type === values?.type).map(provider => {
            return <ProviderBillingCodes key={`provider-bill-codes-${provider.id}`} provider={provider} serviceId={serviceId} />
          })}
        </>}
        <Box sx={{ mt: 2 }}>
          <BillingCodeOverview serviceId={serviceId} />
        </Box>
      </Grid>
      <AlertSimpleDialog
        title={t('Deleting service')}
        message={t('delete-confirm-msg', { name: values.name })}
        open={shownDeleteDialog}
        onClose={handleCloseDeleteDialog}
      />
    </Container>
  )

}